import React from 'react'

import { useTranslation } from '@/lib/i18n'

import { StatusChip, StatusChipVariant } from './status-chip'

export const ExtensionStatusChip: React.FC<{ status: boolean }> = ({ status }) => {
    const { t } = useTranslation('common')

    if (status) {
        return <StatusChip variant={StatusChipVariant.green} label={t('enum.extension.ACTIVE')} />
    } else {
        return <StatusChip variant={StatusChipVariant.red} label={t('enum.extension.INACTIVE')} />
    }
}
