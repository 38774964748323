import { Grid2, Stack, Typography } from '@mui/material'

import { HighlightedText } from '../highlighted-text'

import { DataTestIds } from '../../const'

export const AutocompleteOption: React.FC<{
    searchTerm?: string
    option: { id: string; label: string; icon: React.ReactNode; text: string; subtexts: string[]; statusComponent: React.ReactNode }
}> = ({ searchTerm, option }) => {
    return (
        <Grid2
            container
            minHeight={56}
            paddingX={1.5}
            paddingY={1}
            data-testid={DataTestIds.globalSearch.item(option.label)}
            alignItems="center">
            <Grid2 size={1.5} display="flex" alignItems="center">
                {option.icon}
            </Grid2>
            <Grid2 size={8}>
                <Stack>
                    <Typography variant="body3" color="dark.intense">
                        <HighlightedText highlightedText={searchTerm}>{option.text}</HighlightedText>
                    </Typography>
                    {option.subtexts.length > 0 ? (
                        <Typography variant="body3" color="dark.lighter">
                            {option.subtexts.join(' • ')}
                        </Typography>
                    ) : null}
                </Stack>
            </Grid2>
            <Grid2 size={2.5} display="flex" alignItems="center" justifyContent="flex-end">
                {option.statusComponent}
            </Grid2>
        </Grid2>
    )
}
