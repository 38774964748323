import React from 'react'

import { useTranslation } from '@/lib/i18n'

import { StatusChip, StatusChipVariant } from './status-chip'

import { assertUnreachable } from '../../utils/assert-unreachable'

import { LicenceContractStatus } from '../../../lib/generated/graphql'

export const LicenceContractStatusChip: React.FC<{ status: LicenceContractStatus }> = ({ status }) => {
    const { t } = useTranslation('common')

    if (!status) {
        return <>{t('N/A')}</>
    }

    switch (status) {
        case LicenceContractStatus.ACTIVE:
            return <StatusChip variant={StatusChipVariant.green} label={t(`enum.LicenceContractStatus.${status}`)} />
        case LicenceContractStatus.DRAFT:
            return <StatusChip variant={StatusChipVariant.grey} label={t(`enum.LicenceContractStatus.${status}`)} />
        case LicenceContractStatus.INACTIVE:
            return <StatusChip variant={StatusChipVariant.red} label={t(`enum.LicenceContractStatus.${status}`)} />
        default:
            return assertUnreachable(status)
    }
}
