'use client'

import React, { useState } from 'react'

import { useDebounce } from 'react-use'

import { CustomizedAutocomplete, CustomizedAutocompleteProps } from '../customized-autocomplete'
import { AutocompleteItem } from '../types'

import { DEFAULT_SEARCH_TERM_LENGTH } from '../../../const'
import { useLocalizedRouter } from '../../../hooks/use-localized-router'
import { SEARCH_FIELD_WIDTH } from '../../../theme'
import { licenceContractName } from '../../../utils/formatting'

import { useGlobalSearchQuery } from '../../../../lib/generated/graphql'

export const GlobalSearch = () => {
    const router = useLocalizedRouter()

    const [searchTerm, setSearchTerm] = useState('')
    const [localSearchTerm, setLocalSearchTerm] = useState('')

    useDebounce(
        () => {
            const trimmed = localSearchTerm ? localSearchTerm.trim() : ''
            if (trimmed === searchTerm) {
                return
            }

            if (trimmed.length < DEFAULT_SEARCH_TERM_LENGTH) {
                setSearchTerm('')
                return
            }

            setSearchTerm(trimmed)
        },
        500,
        [localSearchTerm],
    )

    const { data, loading } = useGlobalSearchQuery({
        variables: { searchTerm },
        skip: !searchTerm,
    })

    const options: Array<AutocompleteItem> = data
        ? [
              ...data.globalSearch.legalEntities.map((le) => ({
                  label: le.name,
                  legalEntity: le,
                  id: le.id,
              })),
              ...data.globalSearch.licenceContracts.map((lc) => ({
                  label: licenceContractName(lc),
                  licenceContract: lc,
                  id: lc.id,
              })),
              ...data.globalSearch.shops.map((s) => ({
                  label: s.name,
                  shop: s,
                  id: s.id,
              })),
              ...data.globalSearch.propertyLounges.map((pl) => ({
                  label: pl.name,
                  propertyLounge: pl,
                  id: pl.id,
              })),
          ]
        : []

    const onSelectItem: CustomizedAutocompleteProps['onSelectOption'] = (_event, value, _reason) => {
        if (!value) {
            return
        }

        const { legalEntity, licenceContract, shop, propertyLounge } = value as AutocompleteItem

        if (legalEntity) {
            router.push({
                path: 'legalEntityDetail',
                params: { legalEntityId: legalEntity.id },
            })
        }

        if (licenceContract) {
            router.push({
                path: 'licenceContractDetail',
                params: { licenceContractId: licenceContract.id },
            })
        }

        if (shop) {
            router.push({
                path: 'shopDetail',
                params: { shopId: shop.id },
            })
        }

        if (propertyLounge) {
            router.push({
                path: 'shopDetail',
                params: { shopId: propertyLounge.shopId },
            })
        }

        setLocalSearchTerm('')
        setSearchTerm('')
    }

    return (
        <CustomizedAutocomplete
            width={SEARCH_FIELD_WIDTH}
            searchTerm={searchTerm}
            options={options}
            loading={loading}
            onChange={setLocalSearchTerm}
            onSelectOption={onSelectItem}
        />
    )
}
