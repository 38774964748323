import dayjs, { Dayjs } from 'dayjs'
import get from 'lodash.get'
import orderBy from 'lodash/orderBy'

import { formatDate, licenceContractName } from './formatting'
import { parseDate } from './parsing'

import { dateFormat } from '../const/format'

import {
    LicenceContractFeeDetailsFragment,
    LicenceContractLinkFragment,
    LicenceContractStatus,
    LicenseeRoleStatus,
    PropertyLoungeStatus,
    ShopStatus,
} from '../../lib/generated/graphql'

export const byString = (path: string[]) => (a: any, b: any) => get(a, path).toLocaleLowerCase().localeCompare(get(b, path))

export const byName = byString(['name'])

export const byLicenceContractName = (a: LicenceContractLinkFragment, b: LicenceContractLinkFragment) =>
    licenceContractName(a).toLowerCase().localeCompare(licenceContractName(b).toLowerCase())

type GroupedLicenceContractFees = Record<
    string,
    {
        percentageValue: number
        feeValidityStartDate: string
        feeValidityEndDate: string | null
        divisions: string[]
    }
>

export const groupLicenceContractFeesByDateAndPercentage = (licenceContractFees: LicenceContractFeeDetailsFragment[]) => {
    const groups = licenceContractFees.reduce<GroupedLicenceContractFees>((result, fee) => {
        const key = `${formatDate(fee.feeValidityStartDate)}-${dayjs(fee.feeValidityEndDate).format(dateFormat)}_${fee.licenceFeePercentageValue}`
        const existingGroup = result[key]

        if (existingGroup) {
            existingGroup.divisions.push(fee.division)
        } else {
            result[key] = {
                percentageValue: fee.licenceFeePercentageValue,
                feeValidityStartDate: fee.feeValidityStartDate,
                feeValidityEndDate: fee.feeValidityEndDate,
                divisions: [fee.division],
            }
        }

        return result
    }, {})

    const groupsOrderedByStartDate = orderBy(Object.values(groups), (fees) => dayjs(fees.feeValidityStartDate).format(dateFormat), 'asc')

    return groupsOrderedByStartDate.map((fees) => ({
        ...fees,
        divisions: fees.divisions.sort(),
    }))
}

export const sortByDateComparison = (a?: Dayjs | string | null, b?: Dayjs | string | null) => {
    const aDate = a ? (typeof a === 'string' ? parseDate(a) : a) : undefined
    const bDate = b ? (typeof b === 'string' ? parseDate(b) : b) : undefined

    return (aDate && aDate.isValid() ? aDate.valueOf() : 0) - (bDate && bDate.isValid() ? bDate.valueOf() : 0)
}

const sortByStatusAndName = <T extends { name: string; status: S }, S extends string>(items: T[], statusOrder: ReadonlyArray<S>) => {
    return items.sort((a, b) => {
        const statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
        return statusComparison !== 0 ? statusComparison : a.name.localeCompare(b.name)
    })
}

export const sortShopByStatusAndName = <T extends { name: string; status: ShopStatus }>(shops: T[]) =>
    sortByStatusAndName(shops, [ShopStatus.OPENED, ShopStatus.PLANNED, ShopStatus.DRAFT, ShopStatus.CLOSED])

export const sortPropertyLoungesByStatusAndName = <T extends { name: string; status: PropertyLoungeStatus }>(propertyLounges: T[]) =>
    sortByStatusAndName(propertyLounges, [PropertyLoungeStatus.OPEN, PropertyLoungeStatus.CLOSED])

export const sortLicenceContractByStatusAndName = <T extends { name: string; status: LicenceContractStatus }>(licenceContracts: T[]) =>
    sortByStatusAndName(licenceContracts, [LicenceContractStatus.ACTIVE, LicenceContractStatus.DRAFT, LicenceContractStatus.INACTIVE])

export const sortLegalEntityByStatusAndName = <T extends { name: string; status: LicenseeRoleStatus }>(legalEntities: T[]) =>
    sortByStatusAndName(legalEntities, [LicenseeRoleStatus.ACTIVE, LicenseeRoleStatus.INACTIVE])
