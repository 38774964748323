import { useTranslation } from '@/lib/i18n'

import { AutocompleteOption } from './autocomplete-option'
import { AutocompleteItem } from './types'

import { LegalEntityIcon, LicenceContractIcon, PinIcon, PropertyLoungeIcon, ShopIcon } from '../icons'
import { LegalEntityStatusChip, LicenceContractStatusChip, PropertyLoungeStatusChip, ShopStatusChip } from '../status-chip'

import { licenceContractName } from '../../utils/formatting'
import { isSet } from '../../utils/primitives'

type TransformedOption = {
    option: AutocompleteItem
    searchTerm?: string
}

export const TransformedOption: React.FC<TransformedOption> = ({ option, searchTerm }) => {
    const { t } = useTranslation('common')

    const transformOption = (option: AutocompleteItem) => {
        let icon: React.ReactNode = null
        let text: string = ''
        let statusComponent: React.ReactNode = null
        let subtexts: string[] = []

        const { legalEntity, licenceContract, shop, propertyLounge, isGooglePlace } = option

        if (legalEntity) {
            icon = <LegalEntityIcon sx={{ width: 24, height: 24 }} />
            text = legalEntity.name
            statusComponent = <LegalEntityStatusChip status={legalEntity.licenseeRoleStatus} />
            subtexts = [legalEntity.physicalAddress?.countryA3, legalEntity.debtorNumber].filter(isSet)
        }

        if (licenceContract) {
            icon = <LicenceContractIcon sx={{ width: 24, height: 24 }} />
            text = licenceContractName(licenceContract)
            statusComponent = <LicenceContractStatusChip status={licenceContract.status} />

            if (licenceContract.businessUnit) {
                subtexts.push(t(`enum.BusinessUnit.${licenceContract.businessUnit}`))
            }

            if (licenceContract.contractType) {
                subtexts.push(t(`enum.ContractType.${licenceContract.contractType}`))
            }
        }

        if (shop) {
            icon = <ShopIcon sx={{ width: 24, height: 24 }} />
            text = shop.name
            statusComponent = <ShopStatusChip status={shop.status} />

            if (shop.physicalAddress?.countryA3) {
                subtexts.push(shop.physicalAddress?.countryA3)
            }

            if (shop.licenceArea?.licenceContract?.businessUnit) {
                subtexts.push(t(`enum.BusinessUnit.${shop.licenceArea.licenceContract.businessUnit}`))
            }
        }

        if (propertyLounge) {
            const { shop } = propertyLounge
            icon = <PropertyLoungeIcon sx={{ width: 24, height: 24 }} />
            text = propertyLounge.name
            statusComponent = <PropertyLoungeStatusChip status={propertyLounge.status} />

            if (propertyLounge.physicalAddress?.countryA3) {
                subtexts.push(propertyLounge.physicalAddress?.countryA3)
            }

            if (shop?.licenceArea?.licenceContract?.businessUnit) {
                subtexts.push(t(`enum.BusinessUnit.${shop.licenceArea.licenceContract.businessUnit}`))
            }
        }

        if (isGooglePlace) {
            icon = <PinIcon sx={{ width: 24, height: 24 }} />
            text = option.label
        }

        return {
            id: option.id,
            label: option.label,
            icon,
            statusComponent,
            text,
            subtexts,
        }
    }

    return <AutocompleteOption option={transformOption(option)} searchTerm={searchTerm} />
}
