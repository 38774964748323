'use client'

import React from 'react'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { Box } from '@mui/material'
import type { SxProps } from '@mui/material/styles'

import { removeLangPrefix } from './remove-lang-prefix'

import { WrapWithIf } from '../wrap-with-if'

import { DRAWER_WIDTH } from '../../theme'
import { palette } from '../../theme/palette'

type DrawerIconProps = {
    href: string
    icon: React.ReactNode
}

export const DrawerIcon: React.FC<DrawerIconProps> = ({ href, icon }) => {
    const pathname = usePathname()

    const color = href ? palette.secondary.active : palette.secondaryAlt.active

    const entityPath = removeLangPrefix(href)
    const isOnActiveRoute = pathname?.includes(entityPath)

    const activeUrlOverrides: SxProps = {
        ...(href ? { cursor: 'pointer' } : {}),
        ...(href && isOnActiveRoute
            ? {
                  color: palette.primary.main,
                  borderRight: `1px solid ${palette.primary.main}`,
              }
            : {}),
    }

    return (
        <WrapWithIf condition={Boolean(href)} render={(children) => <Link href={href}>{children}</Link>}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={56}
                width={DRAWER_WIDTH - 1}
                color={color}
                sx={activeUrlOverrides}>
                {icon}
            </Box>
        </WrapWithIf>
    )
}
