import dayjs, { Dayjs } from 'dayjs'

import { dateFormat } from '../const/format'

export const parseDate = (date: string): Dayjs | null => (date ? dayjs(date, dateFormat) : null)

export const parseStringToDayjsUtc = (date?: string | null): Dayjs | null => {
    if (!date) {
        return null
    }
    return dayjs(date).isValid() ? dayjs.utc(date) : null
}
