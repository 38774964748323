import { useMemo } from 'react'

import { useTranslation } from '@/lib/i18n'

import { groupAndSortOptions } from './group-and-sort-options'

import { AutocompleteItem } from '../types'

export type OptionGroup = {
    id: string
    options: AutocompleteItem[]
    previousGroupsLength: number
    title: string
}

type UseGroupedAndSortedOptions = (groupedOptions: AutocompleteItem[]) => OptionGroup[]

export const useGroupedAndSortedOptions: UseGroupedAndSortedOptions = (options) => {
    const { t } = useTranslation('common')

    return useMemo(() => groupAndSortOptions({ t, options }), [options, t])
}
