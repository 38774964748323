import React from 'react'

import { useTranslation } from '@/lib/i18n'
import { Box, Divider, Stack, Typography } from '@mui/material'

import { FeatureSwitch, useFeature } from '../../features'

import { Feature } from '../../../const/feature'

export const DeveloperMenu = () => {
    const { t } = useTranslation('common')
    const { enabled, features } = useFeature(Feature.DeveloperFeatures)

    if (!enabled) {
        return null
    }

    return (
        <>
            <div />
            <Divider sx={{ marginBottom: 1 }} />
            <Box marginLeft={1}>
                <Typography variant="body3" color="dark.simple">{`${t('features.DeveloperFeatures')}:`}</Typography>

                {features ? (
                    <Stack marginLeft={2.5}>
                        {Object.keys(features).map((f) => (
                            <FeatureSwitch key={f} feature={f as Feature} />
                        ))}
                    </Stack>
                ) : null}
            </Box>
        </>
    )
}
