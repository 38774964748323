import React from 'react'

import { useTranslation } from '@/lib/i18n'

import { StatusChip, StatusChipVariant } from './status-chip'

import { assertUnreachable } from '../../utils/assert-unreachable'

import { PropertyLoungeStatus } from '../../../lib/generated/graphql'

export const PropertyLoungeStatusChip: React.FC<{ status: PropertyLoungeStatus }> = ({ status }) => {
    const { t } = useTranslation('common')

    if (!status) {
        return <>{t('N/A')}</>
    }

    switch (status) {
        case PropertyLoungeStatus.OPEN:
            return <StatusChip variant={StatusChipVariant.green} label={t(`enum.PropertyLoungeStatus.${status}`)} />
        case PropertyLoungeStatus.CLOSED:
            return <StatusChip variant={StatusChipVariant.red} label={t(`enum.PropertyLoungeStatus.${status}`)} />
        default:
            return assertUnreachable(status)
    }
}
