import React from 'react'

import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const MapIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 18 18">
        <path
            fillRule="evenodd"
            d="M0 9a9 9 0 1 1 18 0A9 9 0 1 1 0 9zm1.246-1.976C1.085 7.656 1 8.318 1 9c0 .691.088 1.361.252 2h15.496c.165-.639.252-1.309.252-2 0-.682-.085-1.344-.246-1.976H1.246zm.326-1h14.856C15.247 3.079 12.367 1 9 1S2.753 3.079 1.572 6.024zM9 17c-3.357 0-6.232-2.068-7.418-5h14.837c-1.187 2.932-4.061 5-7.419 5z"
        />
        <path
            fillRule="evenodd"
            d="M5.938 2.813C6.535 1.231 7.521 0 9 0s2.48 1.226 3.096 2.808c.629 1.617.944 3.81.944 6.192s-.315 4.575-.944 6.192C11.48 16.774 10.476 18 9 18s-2.465-1.231-3.062-2.813c-.61-1.616-.907-3.808-.907-6.187s.297-4.571.907-6.187zm.936.353C6.322 4.626 6.031 6.685 6.031 9s.291 4.374.842 5.834C7.437 16.328 8.186 17 9 17s1.583-.677 2.164-2.171c.568-1.46.876-3.517.876-5.829s-.308-4.37-.876-5.829C10.583 1.677 9.818 1 9 1s-1.563.672-2.127 2.166z"
        />
    </SvgIcon>
)
