export const DataTestIds = {
    globals: {
        header: {
            icon: 'header.icon',
        },
        pagination: 'pagination',
        search: 'search',
        selectValue: (dataTestId: string, value: string) => `${dataTestId}_${value}`,
    },
    globalSearch: {
        input: 'globalSearch.input',
        item: (value: string) => `globalSearch_${value}`,
    },
    licenceContracts: {
        filters: {
            businessUnit: 'businessUnit',
            contractType: 'contractType',
            licenceContractStatus: 'licenceContractStatus',
        },
    },
    legalEntities: {
        filters: {
            selectableAsLicensor: 'selectableAsLicensor',
            companyOwnedOperation: 'companyOwnedOperation',
        },
    },
    shop: {
        statusSelect: 'statusSelect',
        selectValue: (dataTestId: string, value: string) => `${dataTestId}_${value}`,
        shopStatus: 'shopStatus',
        shopStatusValue: (dataTestId: string, value: string) => `${dataTestId}_${value}`,
    },
}
