import { TFunction } from '@/lib/i18n'

import { OptionGroup } from './use-grouped-and-sorted-options'

import { AutocompleteItem } from '../types'

import {
    sortLegalEntityByStatusAndName,
    sortLicenceContractByStatusAndName,
    sortPropertyLoungesByStatusAndName,
    sortShopByStatusAndName,
} from '../../../utils/sorting'

type GroupingOptions = {
    t: TFunction
    options: AutocompleteItem[]
}

export const groupAndSortOptions = ({ t, options }: GroupingOptions): OptionGroup[] => {
    const legalEntities = sortLegalEntityByStatusAndName(
        options
            .filter(
                (option): option is AutocompleteItem & { legalEntity: NonNullable<AutocompleteItem['legalEntity']> } =>
                    !!option.legalEntity,
            )
            .map((item) => ({
                name: item.label,
                status: item.legalEntity.licenseeRoleStatus,
                entity: item,
            })),
    ).map((item) => item.entity)

    const licenceContracts = sortLicenceContractByStatusAndName(
        options
            .filter(
                (option): option is AutocompleteItem & { licenceContract: NonNullable<AutocompleteItem['licenceContract']> } =>
                    !!option.licenceContract,
            )
            .map((item) => ({
                name: item.label,
                status: item.licenceContract.status,
                entity: item,
            })),
    ).map((item) => item.entity)

    const shops = sortShopByStatusAndName(
        options
            .filter((option): option is AutocompleteItem & { shop: NonNullable<AutocompleteItem['shop']> } => !!option.shop)
            .map((item) => ({
                name: item.label,
                status: item.shop.status,
                entity: item,
            })),
    ).map((item) => item.entity)

    const propertyLounges = sortPropertyLoungesByStatusAndName(
        options
            .filter(
                (option): option is AutocompleteItem & { propertyLounge: NonNullable<AutocompleteItem['propertyLounge']> } =>
                    !!option.propertyLounge,
            )
            .map((item) => ({
                name: item.label,
                status: item.propertyLounge.status,
                entity: item,
            })),
    ).map((item) => item.entity)

    const googlePlaces = options.filter((option) => option.isGooglePlace)

    return [
        {
            id: 'legalEntities',
            options: legalEntities,
            previousGroupsLength: 0,
            title: t('legalEntities'),
        },
        {
            id: 'licenceContracts',
            options: licenceContracts,
            previousGroupsLength: legalEntities.length,
            title: t('contracts'),
        },
        {
            id: 'shops',
            options: shops,
            previousGroupsLength: legalEntities.length + licenceContracts.length,
            title: t('shops'),
        },
        {
            id: 'propertyLounges',
            options: propertyLounges,
            previousGroupsLength: legalEntities.length + licenceContracts.length + shops.length,
            title: t('propertyLounges'),
        },
        {
            id: 'googlePlaces',
            options: googlePlaces,
            previousGroupsLength: legalEntities.length + licenceContracts.length + shops.length + propertyLounges.length,
            title: t('licenceArea.search.googleResults'),
        },
    ]
}
