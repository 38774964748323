import { Fragment } from 'react'

import { Typography, UseAutocompleteRenderedOption } from '@mui/material'

import { TransformedOption } from './transformed-option'
import { AutocompleteItem } from './types'
import { OptionGroup } from './use-grouped-and-sorted-options/use-grouped-and-sorted-options'

export const CustomizedAutocompleteItem: React.FC<{
    groupedAndSortedOptions: OptionGroup[]
    getOptionProps: (renderedOption: UseAutocompleteRenderedOption<AutocompleteItem>) => React.HTMLAttributes<HTMLLIElement>
    searchTerm?: string
}> = ({ groupedAndSortedOptions, getOptionProps, searchTerm }) => {
    return (
        <>
            {groupedAndSortedOptions.map(({ options, title, id, previousGroupsLength }) =>
                options.length > 0 ? (
                    <Fragment key={id}>
                        <Typography variant="body4" color="dark.lighter" padding={1}>
                            {title}
                        </Typography>
                        {options.map((option, index) => (
                            <li {...getOptionProps({ option, index: index + previousGroupsLength })} key={option.id}>
                                <TransformedOption option={option} searchTerm={searchTerm} />
                            </li>
                        ))}
                    </Fragment>
                ) : null,
            )}
        </>
    )
}
