import React from 'react'

import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

import { palette } from '../../theme/palette'
import { assertUnreachable } from '../../utils/assert-unreachable'

export const StyledStatusChip = styled(Chip)({
    borderRadius: 4,
    height: 24,
    width: 'max-content',
    '& span': {
        padding: 5,
    },
})

export enum StatusChipVariant {
    green = 'green',
    grey = 'grey',
    red = 'red',
}

export const StatusChip: React.FC<{ variant: StatusChipVariant; label: string; dataTestId?: string }> = ({
    variant,
    label,
    dataTestId,
}) => {
    switch (variant) {
        case StatusChipVariant.green:
            return (
                <StyledStatusChip
                    sx={{ color: palette.success.main, backgroundColor: palette.success.light }}
                    label={label}
                    data-testid={dataTestId}
                />
            )
        case StatusChipVariant.red:
            return (
                <StyledStatusChip
                    sx={{ color: palette.error.main, backgroundColor: palette.error.light }}
                    label={label}
                    data-testid={dataTestId}
                />
            )
        case StatusChipVariant.grey:
            return (
                <StyledStatusChip
                    sx={{ color: palette.dark.lighter, backgroundColor: palette.onColouredSurface.hover }}
                    label={label}
                    data-testid={dataTestId}
                />
            )
        default:
            return assertUnreachable(variant)
    }
}
