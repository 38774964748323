'use client'

import React from 'react'

import { Box, Drawer as MuiDrawer, Typography } from '@mui/material'

import { DrawerIcon } from './drawer-icon'

import { LegalEntityIcon, LicenceContractIcon, MapIcon, ShopIcon } from '../icons'

import config from '../../config'
import { useLanguage } from '../../hooks/use-language'
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../theme'

export const SideDrawer: React.FC = () => {
    const language = useLanguage()

    return (
        <MuiDrawer
            sx={(theme) => ({
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    zIndex: 0,
                    width: DRAWER_WIDTH,
                    height: `calc(100% - ${HEADER_HEIGHT}px)`,
                    top: HEADER_HEIGHT,
                    borderRight: `1px solid ${theme.palette.light.lightShade}`,
                    background: theme.palette.light.lightShade40,
                },
            })}
            variant="permanent"
            anchor="left">
            <Box display="flex" flexDirection="column" paddingTop={2} gap={1}>
                <DrawerIcon icon={<LegalEntityIcon fontSize="small" />} href={`/${language}/legal-entities`} />
                <DrawerIcon icon={<LicenceContractIcon fontSize="small" />} href={`/${language}/licence-contracts`} />

                <DrawerIcon icon={<MapIcon fontSize="small" />} href={`/${language}/licence-areas`} />

                <DrawerIcon icon={<ShopIcon fontSize="small" />} href={`/${language}/shops`} />
            </Box>

            <Box flexGrow={1} />

            <Box display="flex" justifyContent="center">
                <Typography variant="subtitle1" color="#e9e9e9">
                    {config.version.slice(0, 8)}
                </Typography>
            </Box>
        </MuiDrawer>
    )
}
