import React from 'react'

import { useTranslation } from '@/lib/i18n'

import { StatusChip, StatusChipVariant } from './status-chip'

import { DataTestIds } from '../../const'
import { assertUnreachable } from '../../utils/assert-unreachable'

import { ShopStatus } from '../../../lib/generated/graphql'

export const ShopStatusChip: React.FC<{ status: ShopStatus }> = ({ status }) => {
    const { t } = useTranslation('common')

    if (!status) {
        return <>{t('N/A')}</>
    }

    switch (status) {
        case ShopStatus.OPENED:
            return (
                <StatusChip
                    variant={StatusChipVariant.green}
                    label={t(`enum.ShopStatus.${status}`)}
                    dataTestId={DataTestIds.shop.shopStatusValue(DataTestIds.shop.shopStatus, status)}
                />
            )
        case ShopStatus.PLANNED:
        case ShopStatus.DRAFT:
            return (
                <StatusChip
                    variant={StatusChipVariant.grey}
                    label={t(`enum.ShopStatus.${status}`)}
                    dataTestId={DataTestIds.shop.shopStatusValue(DataTestIds.shop.shopStatus, status)}
                />
            )
        case ShopStatus.CLOSED:
            return (
                <StatusChip
                    variant={StatusChipVariant.red}
                    label={t(`enum.ShopStatus.${status}`)}
                    dataTestId={DataTestIds.shop.shopStatusValue(DataTestIds.shop.shopStatus, status)}
                />
            )
        default:
            return assertUnreachable(status)
    }
}
