'use client'

import React, { useState } from 'react'

import { signOut } from 'next-auth/react'

import { useSession } from '@/lib/auth/use-session'
import { useTranslation } from '@/lib/i18n/use-translation'
import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'

import { DeveloperMenu } from './developer-menu'
import { LanguageSwitch } from './language-switch'
import { UserRolesAndCountryAccessMenu } from './user-roles-and-country-access-menu'

import { DataTestIds } from '../../../const'
import { formatNameAbbrevation } from '../../../utils/formatting'

export const UserMenu: React.FC = () => {
    const { t } = useTranslation('common')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const { session } = useSession()
    const { user } = session || {}
    const { name, email, image } = user || {}

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Box display="flex" alignItems="center">
            <IconButton onClick={handleMenu}>
                <Avatar src={image || undefined} data-testid={DataTestIds.globals.header.icon}>
                    <Typography variant="body4" color="light.white">
                        {formatNameAbbrevation(t, name)}
                    </Typography>
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            minHeight: 'fit-content',
                        },
                    },
                }}>
                <MenuItem>
                    <LanguageSwitch />
                </MenuItem>

                <Divider />

                <Box display="flex" flexDirection="column" paddingX={2}>
                    <Typography variant="body3" color="dark.intense">
                        {name}
                    </Typography>

                    <Typography variant="body3" color="dark.subdued">
                        {email}
                    </Typography>
                </Box>

                <UserRolesAndCountryAccessMenu />

                <DeveloperMenu />

                <Divider sx={{ marginTop: 1 }} />

                <MenuItem onClick={() => signOut({ callbackUrl: '/api/auth/logout' })}>{t('logout')}</MenuItem>
            </Menu>
        </Box>
    )
}
